<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-vendor
            type="search"
            label="점검업체"
            name="vendorCds"
            v-model="searchParam.vendorCd">
          </c-vendor>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 점검년월(예정, 완료) -->
          <c-datepicker
            label="점검년월"
            name="month"
            type="month"
            v-model="searchParam.month">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="SAI_CHECK_STATUS_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="checkStatusCd"
            label="진행상태"
            v-model="searchParam.checkStatusCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="안전순찰 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :checkClickFlag="false"
      rowKey="safetyCheckId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group v-if="isPlant" outline>
          <c-btn label="등록" v-if="editable" :editable="editable" icon="add" @btnClicked="onItemClick" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
        <q-btn-group v-else outline>
          <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
            ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
          </font>
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "safety-check",
  props: {
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            style: 'width:150px',
            label: "공사현장",
            align: "center",
            sortable: false,
          },
          {
            name: "checkMonth",
            field: "checkMonth",
            style: 'width:120px',
            label: "점검년월",
            align: "center",
            sortable: false,
          },
          {
            name: "vendorName",
            field: "vendorName",
            style: 'width:160px',
            label: "점검업체",
            align: "center",
            sortable: false,
          },
          {
            name: "safetyCheckName",
            field: "safetyCheckName",
            style: 'width:200px',
            label: "점검명",
            align: "left",
            type: "link",
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선(건)',
            align: 'center',
            child: [
              {
                name: 'improveCount',
                field: 'improveCount',
                label: '조치완료',
                align: 'center',
                style: 'width:80px',
                sortable: true,
              },
              { 
                name: 'resImproveCount',
                field: 'resImproveCount',
                label: '잔여부적합',
                align: 'center',
                style: 'width:80px',
                sortable: true,
              },
            ]
          },
          {
            name: "checkStatusName",
            field: "checkStatusName",
            label: "진행단계",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재진행단계',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        month: '',
        checkStatusCd: null,
        vendorCd: null,
      },
      editable: true,
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      selected: [],
      isVendor: false,
      isPlant: true,
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (this.$store.getters.user.innerFlag === 'N') {
        this.searchParam.vendorCd = this.$store.getters.user.deptCd;
        this.isVendor = true;
      }
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      // url setting
      this.listUrl = selectConfig.sai.safetycheck.plan.list.url;
      this.searchParam.month = this.$comm.getThisMonth();
      this.getList();
    },
    getList() {
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.param = {
        safetyCheckId: row ? row.safetyCheckId : '',
      };
      this.popupOptions.title = '안전순찰 상세'; 
      this.popupOptions.target = () =>
        import(`${"./safetyCheckDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    onItemClick(type) {
      this.linkClick(null);
    },
    
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$refs['table'].selected = [];
      this.getList();
    },
  },
};
</script>